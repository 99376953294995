<!-- 页面程序 -->
<template>  
    <div class="content" >
      <img class="topImage" src="../../assets/img/icon_weak_top.png" alt="" />
      <div class="content_list">
        <img class="orderImage" src="../../assets/img/icon_weak_order.png" alt="" @click="getOrder()"/>
        <img class="contentImage" style="margin-top: 20px;" src="../../assets/img/icon_weak_content.png" alt="" />
        <img class="contentImage" style="margin-top: 20px;" src="../../assets/img/icon_weak_bottom.png" alt="" />
      </div>
      <div class="porject_recommend">
        <section style="display: flex;justify-content: center;align-items: center; height: 40px; color: #037ED5;">
            <span style="width: 50px; height: 1px; background: #037ED5;" ></span>
            <span style="margin-left: 20px;margin-right: 20px;">推荐服务</span>
            <span style="width: 50px; height: 1px; background: #037ED5;"></span>
        </section>
        <section class="porject_recommend_bottom">
            <div class="porject_recommend_scroll_item" v-for="(item, index) in recommendList" :key="index" @click="getServiceData(item)">
                <section style="display: flex;flex-direction: column;">
                    <img class="spikeByTime_img" style="border-radius:10px; height: 120px;" :src="item.photoPath" />
                    <span class="spikeByTime_name">{{item.projectName}}</span>
                    <span class="spikeByTime_desc">{{item.description}}</span>
                    <div class="spikeByTime_price">
                        <span class="font16" style="width: 50%;font-weight: 800; color: #EE4908;">￥{{1 > item.oltCount ? (item.minimumNewcomerDiscount/100).toFixed(2) : (item.lowestDiscountPrice/100).toFixed(2)}}</span>
                        <span style="color: #666666;text-decoration: line-through;font-size: 12px;">￥{{(item.theLowestPrice/100).toFixed(2)}}</span>
                    </div>
                </section>
            </div>
        </section>
      </div>
      <div class="bottom_img">
          <img src="../../assets/img/icon_weak_down.png"  style="width: 100% ; height: 100%; "/>
      </div>
    </div>
  </template>
  
  
  <!-- JS交互 -->
  <script>
  export default {
    components: {},
    data() {
      return {
          memberId: this.$route.query.memberId,
          recommendList: [],
      };
    },
    methods: {
      getReferralServiceList() {
          let params = {
              placeAnOrder: 0,
              memberId: this.memberId,
          };
          this.$axios({
              url: this.$requests.member.getNewActiveSalesList,
              method: "post",
              data: params,
          }).then((res) => {
              let wrapper = res.data || {};
              this.recommendList = wrapper.data || [];
          }).catch((e) => {});
      },
      getOrder(){
        let href =  "next://goToAllService"
        window.location.href = href;
      },
      getServiceData(e){
        let href =  "next://getServiceData" + ',' + e.projectId
        window.location.href = href;
      },
    },
    mounted() {
      // 获取推荐数据
      this.getReferralServiceList();
    },
  };
  </script>
  
  <!-- class 展示属性 -->
  <style lang="less" scoped>
  .content {
      background-image: url("../../assets/img/icon_weak_bg.png");
  }
  .topImage{
    width: 100%;
  }

  .content_list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -90px;
  }

  .orderImage{
    width: 90%;
  }


  .contentImage{
    width: 96%;
  }

  .porject_recommend {
      width: 100%;
      height: 230px;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .bottom_img {
      width: 100%;
      margin-top: 20px;
  }
  
  .porject_recommend_bottom {
      height: 205px;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;
      overflow-x: scroll;
      white-space: nowrap;
      overflow-y: hidden;
  }
  .porject_recommend_scroll_item {
      margin-top: 5px;
      margin-right: 5px;
      height: 180px;
      width: 35vw;
      background-color: white;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      border: 2px solid #FFFFFF;
      box-shadow: 0px 1px 9px 1px rgba(179,110,69,0.4600);
      border-radius: 5px;
      overflow: hidden;
      display: inline-table;
  }


  .spikeByTime_img {
      width: 100%;
      height: 55%;
  }
  .spikeByTime_name{
      overflow: hidden;
      text-overflow: ellipsis;
      width: 33vw;
      margin-top: 2px;
      margin-left: 1vw;
      font-size: 14px;
      color: #333333;
      font-weight: 500;
  }
  .spikeByTime_desc{
      overflow: hidden;
      text-overflow: ellipsis;
      width: 33vw;
      margin-top: 5px;
      margin-left: 1vw;
      font-size: 11px;
      color: #666666;
      font-weight: 500;
  }
  .spikeByTime_price{
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 20px;
      align-items: baseline;
  }
  </style>